import { get, pick } from 'lodash';

import { ATTRIBUTES as A } from 'helpers/analyticsAttributes';
import * as Schemas from 'helpers/analyticsSchemas';
import { PLATFORM } from 'helpers/constants';
import { formatCuisineString } from 'helpers/format';
import {
  getUserExperimentId,
  getUserAuthStatus,
  getCnChannel,
} from 'helpers/customer';
import {
  isMarketplacePlatform,
  isYelpPlatform,
} from '@chownow/cn-web-utils/url';

export const ATTRIBUTES = A;

export function orderAttributes(order) {
  const isOrderAhead = !!order.when;

  return {
    [A.orderAheadDatetime]: isOrderAhead ? order.when : '',
    [A.orderIsAsap]: !isOrderAhead,
    [A.orderIsReorder]: !!order.originalOrderId,
    [A.orderTotal]: order.total,
    [A.orderType]: order.fulfillmentMethod,
    [A.hasDeliveryFee]: !!order.delivery_fee,
    [A.deliveryFeeAmount]: order.delivery_fee || 0,
    [A.hasMiscFee]: !!get(order.misc_fee, 'amount'),
    [A.miscFeeAmount]: get(order.misc_fee, 'amount'),
    [A.miscFeeName]: get(order.misc_fee, 'label'),
  };
}

export function restaurantAttributes(restaurant) {
  return {
    [A.restaurantBrandId]: restaurant.company_id,
    [A.restaurantLocationCategory]: formatCuisineString(restaurant.cuisines),
    [A.restaurantLocationId]: restaurant.id,
    [A.restaurantLocationName]: restaurant.name,
  };
}

export function attributesForPlatform({ platform, eventName, attributes }) {
  const schemas = {
    [PLATFORM.direct]: Schemas.DirectSchema,
    [PLATFORM.marketplace]: Schemas.MarketplaceSchema,
    [PLATFORM.yelp]: Schemas.YelpSchema,
  };

  // if the platform doesn't have a schema yet: cut it a break
  // and let the attributes through
  const schema = schemas[platform];
  if (!schema) {
    return attributes;
  }

  // if the event name isn't yet included in the schema: assume the attributes aren't
  // platform-differentiated and we should pass them all through
  const attributesToKeep = schema.events[eventName];
  if (!attributesToKeep) {
    return attributes;
  }

  // otherwise keep only the attributes included in the schema.
  // If we need something more sophisticated (ie apply a transformation function
  // for attributes) we'd change that here and in the schema
  return pick(attributes, attributesToKeep);
}

export const HERMOSA_MARKETPLACE_IDS = {
  direct: '',
  yelp: 1,
  marketplace: 2,
  google: 3,
};

export function getFullAttributes({ attributes: givenAttributes, eventName }) {
  const userExperimentId = getUserExperimentId();
  const authStatus = getUserAuthStatus();
  const cnChannel = getCnChannel();
  let hermosaMarketplaceID = HERMOSA_MARKETPLACE_IDS.direct;
  let platform = PLATFORM.direct;

  if (isMarketplacePlatform()) {
    hermosaMarketplaceID = HERMOSA_MARKETPLACE_IDS.marketplace;
    platform = PLATFORM.marketplace;
  } else if (isYelpPlatform()) {
    hermosaMarketplaceID = HERMOSA_MARKETPLACE_IDS.yelp;
    platform = PLATFORM.yelp;
  }

  const attributes =
    attributesForPlatform({
      attributes: givenAttributes,
      platform,
      eventName,
    }) || {};

  // Add Hermosa Marketplace Id & Experiment Key
  const fullAttributes = {
    ...attributes,
    cn_channel: cnChannel,
    experiment_key: userExperimentId,
    hermosa_marketplace_id: hermosaMarketplaceID,
    user_is_authenticated: !!authStatus,
  };

  return fullAttributes;
}
