export const ORDER_ATTRIBUTES = {
  orderAheadDatetime: 'order_ahead_datetime',
  orderIsAsap: 'order_is_asap',
  orderIsReorder: 'order_is_reorder',
  orderTotal: 'order_total',
  orderType: 'order_type',
  hasDeliveryFee: 'has_delivery_fee',
  deliveryFeeAmount: 'delivery_fee_amount',
  hasMiscFee: 'has_misc_fee',
  managedDeliveryId: 'managed_delivery_id',
  miscFeeAmount: 'misc_fee_amount',
  miscFeeName: 'misc_fee_name',
};

export const RESTAURANT_ATTRIBUTES = {
  restaurantBrandId: 'restaurant_brand_id',
  restaurantLocationCategory: 'restaurant_location_category',
  restaurantLocationId: 'restaurant_location_id',
  restaurantLocationName: 'restaurant_location_name',
};

export const MENU_ATTRIBUTES = {
  menuId: 'menu_id',
  menuItemId: 'menu_item_id',
  menuItemSource: 'menu_item_source',
  menuItemLocation: 'menu_item_location',
};

export const ATTRIBUTES = {
  ...MENU_ATTRIBUTES,
  ...ORDER_ATTRIBUTES,
  ...RESTAURANT_ATTRIBUTES,

  cnChannel: 'cn_channel',
  errorReason: 'error_reason',
  experimentKey: 'experiment_key',
  hasAutoPromo: 'has_auto_promo',
  hasCoverPhoto: 'has_cover_photo',
  hasMarketplaceDinerFee: 'has_marketplace_diner_fee',
  hermosaMarketplaceId: 'hermosa_marketplace_id',
  isDelete: 'is_delete',
  isDirectToMarketplaceRedirect: 'is_direct_to_marketplace_redirect',
  isEmbeddedSite: 'is_embedded_site',
  isCartUpdate: 'is_cart_update',
  isSaved: 'is_saved',
  itemHasPhoto: 'item_has_photo',
  itemHasNestedMods: 'item_has_nested_mods',
  itemIsPopularItem: 'item_is_popular_item',
  marketplaceDinerFeeAmount: 'marketplace_diner_fee_amount',
  paymentType: 'payment_type',
  paymentTypeDisplayed: 'payment_type_displayed',
  url: 'url',
  userIsAuthenticated: 'user_is_authenticated',
  totalAmount: 'total_amount',
};
